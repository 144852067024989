.css-1mhcdve-MuiPieArc-root {
  stroke: none !important;
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  button {
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 0 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      background-color: #CA7F5A;
      color: white;
    }

    &:disabled {
      background-color: #e0e0e0;
      cursor: not-allowed;
    }
  }

  span {
    margin: 0 10px;
    font-size: 16px;
    font-weight: normal;
    color: #333;
  }

  .active {
    background-color: #bab0ab;
    color: white;
  }
}




.lineChartContainer {
  @media screen and (max-width: 1000px) {
    width: 415px;
    overflow: auto;
  }
  
  > div {
    padding: 20px;
  }
}



.MuiChartsTooltip-labelCell, .MuiChartsTooltip-valueCell {
  font-size: 15px !important;
}



.step-wrap-container-dashboard {
  width: 90% !important;

  .step-container {
    width: 100%;
    background-color: #ffffff !important;
  }

  h1, h2, h3, div {
    color: #181818 !important;
    font-weight: bold;
  }

  .dashboard-container { //grid container
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;


  


    .chart1 { //Esta es la tabla que aparece al analizar un asesor, nos muestra los registros que tiene
      grid-area: 1 / 1 / 2 / 2;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .chart2 { 
      grid-area: 1 / 2 / 2 / 3; 
      display: grid;
      place-items: center;
    }
    .chart3 { 
      grid-area: 2 / 1 / 3 / 2;
      display: grid;
      place-items: center;
    }
    .chart4 { 
      grid-area: 2 / 2 / 3 / 3;
      display: grid;
      place-items: center;
    }



    @media screen and (max-width: 1000px) {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      
      .chart1 { grid-area: 1 / 1 / 2 / 2; }
      .chart2 { grid-area: 2 / 1 / 3 / 2; }
      .chart3 { grid-area: 3 / 1 / 4 / 2; }
      .chart4 { grid-area: 4 / 1 / 5 / 2; }
    }
  }
}


.step-wrap-container-searcher {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  padding: 40px 50px;
  
  width: 80%;
  height: 400px;

  background-color: #fff8f5;
  border-radius: 25px;

  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);

  h1 {
    color: #181818 !important;
    font-weight: bolder;
  }
}