.registros-table-container {
  max-height: 300px;
  width: 100%;
  overflow-y: auto; // Agregar desplazamiento vertical si la tabla excede la altura máxima


  
  &.small  {
    .registros-table {
      th, td {
        font-size: 13px;
        color: rgb(29, 29, 29);
      }
    }
  }
  
  .registros-table {
    width: 100%;
    border-collapse: collapse;


    th, td {
      padding: 10px;
      text-align: left;
      border-bottom: 1px solid #ccc;
      font-size: 16px; // Tamaño de fuente aumentado
    }

    th {
      background-color: #9370db;
      color: white !important;
    }

    tbody tr {
      &:hover {
        background-color: #f0f0f0;
        cursor: pointer;
      }
    }
  }
}